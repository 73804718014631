import { Box, ButtonBase, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { mediaInteraction } from "../../../../analytics/Posthog";
import { getStorageURL } from "../../../../firebase";
import { getLanguageText } from "../../../../languagesupport";
import IconSwitcher from "../../../../shared/IconSwitcher";
import { ContentElementProps, IconLink, ImagesElement } from "../../../../types/bll";
import InteractionBar from "../components/InteractionBar";
import "./ImageViewer.css";

export const ImageViewer = (props: ContentElementProps) => {
    const { baseUrl, size, title, format, description, socials, paths, descriptions } = props.contentElement.data as ImagesElement;
    // Create a state variable to keep track of the current image
    const [urls, setUrls] = useState<string[]>();
    const [currentImage, setCurrentImage] = useState<string>();
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    useEffect(() => {
        const fetchUrls = async () => {
            const urls = await Promise.all(
                Array.from(Array(size).keys()).map(async (num) => await getStorageURL(baseUrl + "/" + (num + 1) + "." + format))
            );
            setUrls(urls);
            setCurrentImage(urls[0]);
        };
        if (baseUrl) {
            fetchUrls();
        } else if (paths) {
            setUrls(paths);
            setCurrentImage(paths[0]);
        }
    }, [baseUrl, format, paths, size]);

    const [hasInteracted, setHasInteracted] = React.useState<boolean>(false);

    if (!urls) return null;

    const getIconElement = (iconLink: IconLink, key: number) => {
        return (
            <ButtonBase
                key={key}
                onClick={() => {
                    window.open(iconLink.link, "_blank");
                }}
                sx={{ mb: 1, display: "flex", flexDirection: "row" }}
            >
                <IconSwitcher name={iconLink.icon}></IconSwitcher>
                <Typography marginLeft={2}>{iconLink.name}</Typography>
            </ButtonBase>
        );
    };

    return (
        <Box
            sx={{
                width: "100%",
                backgroundColor: "background.default",
                pt: 2,
                pb: 2,
                borderRadius: 5,
            }}
        >
            <ImageGallery
                lazyLoad={true}
                showThumbnails={false}
                onSlide={(currentImageIndex) => {
                    // Update the current image whenever the slide changes
                    if (!hasInteracted) {
                        mediaInteraction(props.contentElement.docId);
                        setHasInteracted(true);
                    }
                    setCurrentImage(urls[currentImageIndex]);
                    setCurrentIndex(currentImageIndex);
                }}
                showPlayButton={false}
                items={urls.map((url) => {
                    return {
                        original: url,
                    };
                })}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    pl: 2,
                    pr: 2,
                    pt: 1,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                    }}
                >
                    {descriptions && (
                        <Typography mt={1} variant="body2">
                            {getLanguageText(descriptions[currentIndex])}
                        </Typography>
                    )}
                    {title ? <Typography variant="h2">{getLanguageText(title)}</Typography> : null}
                    <Typography variant="body2" alignSelf={"start"}>
                        {size} items
                    </Typography>
                    {description ? (
                        <Typography mt={1} variant="body2">
                            {getLanguageText(description)}
                        </Typography>
                    ) : null}
                    {socials ? (
                        <Box
                            sx={{
                                mt: 2,
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            {socials.map((social, key) => {
                                return getIconElement(
                                    {
                                        icon: social.icon,
                                        name: social.name,
                                        link: social.link,
                                    },
                                    key
                                );
                            })}
                        </Box>
                    ) : null}
                </Box>
            </Box>
            <Box sx={{ pr: 2, pl: 2 }}>
                <InteractionBar
                    likedBy={props.contentElement.likedBy}
                    docRef={props.contentElement.docRef}
                    shareUrl={currentImage}
                    isEarlyAccess={false}
                    uploadedBy={props.contentElement.userId}
                ></InteractionBar>
            </Box>
        </Box>
    );
};

export default ImageViewer;
