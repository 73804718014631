import { Box, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import { mediaInteraction } from "../../../analytics/Posthog";
import { EventContext } from "../../../contexts/EventContextProvider";
import { ContentElementProps, SoundCloudElement } from "../../../types/bll";
import InteractionBar from "./components/InteractionBar";
import { getLanguageText } from "../../../languagesupport";

const SoundCloudComponent = (props: ContentElementProps) => {
    const { trackId, description, name } = props.contentElement.data as SoundCloudElement;
    const { darkTheme } = React.useContext(EventContext);

    React.useEffect(() => {
        if (props.track) {
            const interval = setInterval(() => {
                var elem = document.activeElement;
                if (elem && elem.id === trackId) {
                    clearInterval(interval);
                    mediaInteraction(props.contentElement.docId);
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    });

    return (
        <Box
            sx={{
                borderRadius: 5,
                backgroundColor: "background.default",
                overflow: "hidden",
                p: 1,
            }}
        >
            <ThemeProvider theme={darkTheme}>
                <Box
                    sx={{
                        height: 200,
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: 5,
                        backgroundColor: "background.default",
                    }}
                >
                    <iframe
                        id={trackId}
                        width="102%"
                        height="200"
                        allow="autoplay"
                        src={
                            "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" +
                            trackId +
                            "&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                        }
                    ></iframe>
                </Box>
            </ThemeProvider>
            {name ? (
                <Box sx={{ p: 1 }}>
                    <Typography variant="h2" textAlign={"start"}>
                        {getLanguageText(name)}
                    </Typography>
                </Box>
            ) : null}
            {description ? (
                <Box sx={{ p: 1 }}>
                    <Typography variant="body2">{getLanguageText(description)}</Typography>
                </Box>
            ) : null}
            <InteractionBar
                likedBy={props.contentElement.likedBy}
                docRef={props.contentElement.docRef}
                shareUrl={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${trackId}`}
                isEarlyAccess={false}
                uploadedBy={props.contentElement.userId}
            ></InteractionBar>
        </Box>
    );
};

export default SoundCloudComponent;
