import { Box, Checkbox, CircularProgress, Divider, FormControlLabel, TextField, Typography } from "@mui/material";
import { CreateOrderActions, CreateOrderData, OnCancelledActions, OnClickActions } from "@paypal/paypal-js";
import { PayPalButtons, PayPalButtonsComponentProps, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useEffect, useRef } from "react";
import ReactPixel from "react-facebook-pixel";
import { privateLog } from "../../../dev/PrivateConsole";
import { ArtegrityTicket, EventState, OrderInfo, OrderResponse, Quantity, TicketCountResponse } from "../../../types/artegrtiyShopTypes";
import { ArtegrityEventData } from "../../../types/dto";
import ArtegrityOrderSummary from "./components/ArtegrityOrderSummary";
import { TicketTable } from "./components/ArtegrityTicketTable";

type Props = {
    artegrityEvent: ArtegrityEventData;
    artegrityId: string;
    pixel?: boolean;
};

export const TicketShop = ({ artegrityEvent, artegrityId, pixel }: Props) => {
    const [loading, setLoading] = React.useState(false);
    //TODO: clean this up
    const loadTickets: ArtegrityTicket[] = artegrityEvent.pricingTable
        .flatMap((pt, key) => {
            let tickets: ArtegrityTicket[] = [
                {
                    id: pt.pricingIndex,
                    name: pt.name,
                    price: pt.price,
                    currency: artegrityEvent.currencyCode,
                    description: pt.description ? pt.description : undefined,
                    quantity: 0,
                    available: undefined,
                },
            ];

            if (pt.groupPricing) {
                pt.groupPricing.forEach((gp) => {
                    privateLog("debug", "GroupPricing: ", gp);
                    let newTicket = {
                        id: pt.pricingIndex,
                        name: pt.name + ": " + gp.name,
                        price: pt.price - gp.priceReduction,
                        currency: artegrityEvent.currencyCode,
                        description: gp.description,
                        quantity: 0,
                        available: undefined,
                        minQuantity: gp.minQuantity,
                        groupPricingIndex: gp.groupPricingIndex,
                    };
                    tickets.push(newTicket);
                });
            }

            return tickets;
        })
        .sort((a, b) => {
            if (a.id !== b.id) {
                return a.id - b.id;
            }
            return a.price - b.price;
        });
    const [ticketNames, setTicketNames] = React.useState<{ [key: string]: string[] }>({});
    const ticketNamesRef = useRef<{ [key: string]: string[] }>({});
    const handleNameChange = (ticketId: string, index: number, value: string) => {
        const updatedNames = { ...ticketNamesRef.current };
        if (!updatedNames[ticketId]) {
            updatedNames[ticketId] = [];
        }
        updatedNames[ticketId][index] = value;
        ticketNamesRef.current = updatedNames;
        setTicketNames(updatedNames);
    };
    //we need all the refs to update the state in the paypalscriptprovider
    //https://stackoverflow.com/questions/69069357/paypal-button-cannot-read-new-react-state-how-to-work-with-dynamic-values-and-p
    const [tickets, setTickets] = React.useState<ArtegrityTicket[]>(loadTickets);
    const [email, setEmail] = React.useState<string>("");
    const emailRef = React.useRef("");
    const useEmailRef = React.useRef(true);
    const [usePaypalEmail, setUsePaypalEmail] = React.useState<boolean>(true);
    const [signUpPromotional, setSignUpPromotional] = React.useState<boolean>(true);
    const [signUpBackstage, setSignUpBackstage] = React.useState<boolean>(true);

    // Create refs
    const signUpPromotionalRef = useRef(signUpPromotional);
    const signUpBackstageRef = useRef(signUpBackstage);

    // Update refs whenever signUpPromotional or signUpBackstage changes
    useEffect(() => {
        signUpBackstageRef.current = signUpBackstage;
    }, [signUpBackstage]);

    useEffect(() => {
        signUpPromotionalRef.current = signUpPromotional;
    }, [signUpPromotional]);

    const [ticketShopState, setTicketShopState] = React.useState<EventState>();

    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");
    const [res, setRes] = React.useState<OrderInfo | undefined>(undefined);

    function isValidEmail(_email: string) {
        return /\S+@\S+\.\S+/.test(_email);
    }
    //TODO: free events
    //React.useEffect(() => {
    //if (artegrityEvent && artegrityEvent.price === 0) {
    //setUsePaypalEmail(false);
    //}
    //}, [artegrityEvent]);

    React.useEffect(() => {
        privateLog("log", "Calling: ", process.env.REACT_APP_PILOT_BE_URL + "/v0/payment/ticketCount?artegrityId=" + artegrityId);
        fetch(process.env.REACT_APP_PILOT_BE_URL + "/v0/payment/ticketCount?artegrityId=" + artegrityId, {
            method: "POST",
        })
            .then((res) => {
                if (res.status !== 200) {
                    //TODO: we need a base error Page
                }
                return res.json();
            })
            .then((ticketCountResponse: TicketCountResponse) => {
                //TODO: fix this equality check
                setTicketShopState(ticketCountResponse.eventState);
                setTickets((tickets) => {
                    return tickets.map((ticket) => {
                        if (artegrityEvent.eventState === EventState.PAST) {
                            ticket.available = 0;
                        } else {
                            ticket.available = ticketCountResponse.ticketCountPerPrice.find(
                                (tc) => tc.pricingIndex === ticket.id
                            )?.hasTicketsAvailable;
                        }
                        privateLog("debug", "Ticket Updated from Count: ", ticket);
                        return ticket;
                    });
                });
            })
            .catch((error) => {
                privateLog("error", "Error: ", error);
            });
    }, [artegrityId]);

    const setQuantity = (quantity: Quantity) => {
        setError("");
        setTickets((tickets) => {
            return tickets.map((ticket) => {
                if (ticket.id === quantity.id && quantity.groupPricingIndex === ticket.groupPricingIndex) {
                    ticket.quantity = quantity.quantity;
                }
                return ticket;
            });
        });
    };

    const paypalbuttonTransactionProps: PayPalButtonsComponentProps = {
        style: { layout: "vertical", shape: "pill" },
        onClick: (data: Record<string, unknown>, actions: OnClickActions) => onPaypalClick(data, actions),
        createOrder: (data: CreateOrderData, actions: CreateOrderActions) => onCapture(data, actions),
        onApprove: (data, actions) => onApprove(data, actions),
        onCancel: (data: Record<string, unknown>, actions: OnCancelledActions) => onCancel(data, actions),
    };

    function onCancel(data: Record<string, unknown>, actions: OnCancelledActions) {}

    function onPaypalClick(data: Record<string, unknown>, actions: OnClickActions) {
        let _email = emailRef.current;
        let _usePaypalEmail = useEmailRef.current;
        let paymentSource = data["fundingSource"];
        if (paymentSource === "paypal") {
            if (!_usePaypalEmail && !isValidEmail(_email)) {
                setError("The email address you entered is Invalid");
                return actions.reject();
            }
        }
        return actions.resolve();
    }

    function onCapture(data: CreateOrderData, actions: CreateOrderActions) {
        if (artegrityEvent.isPersonalized) {
            for (const ticket of tickets) {
                if (ticket.quantity > 0) {
                    const names = ticketNamesRef.current[ticket.id] || [];
                    if (names.length !== ticket.quantity) {
                        setError("The tickets are personalized so please give a name for everyone.");
                    }
                }
            }
        }
        let b;
        if (artegrityEvent.isPersonalized) {
            b = tickets
                .filter((t) => t.quantity > 0)
                .map((t) =>
                    t.groupPricingIndex
                        ? {
                              names: ticketNamesRef.current[t.id],
                              pricingIndex: t.id,
                              ticketCount: t.quantity,
                              groupPricingIndex: t.groupPricingIndex,
                          }
                        : {
                              names: ticketNamesRef.current[t.id],
                              pricingIndex: t.id,
                              ticketCount: t.quantity,
                          }
                );
        } else {
            b = tickets
                .filter((t) => t.quantity > 0)
                .map((t) =>
                    t.groupPricingIndex
                        ? {
                              pricingIndex: t.id,
                              ticketCount: t.quantity,
                              groupPricingIndex: t.groupPricingIndex,
                          }
                        : { pricingIndex: t.id, ticketCount: t.quantity }
                );
        }

        privateLog("log", "PersonalizedTickets", b);
        privateLog("log", "PersonalizedTicketsCurrState", ticketNamesRef.current[1]);
        let param = new URLSearchParams({
            artegrityId: artegrityId,
        });

        if (!useEmailRef.current && emailRef.current) {
            param.append("email", emailRef.current);
        }

        privateLog("log", "Create Order Params: ", param);

        if (pixel) {
            console.log("Pixel sending AddToCart");
            tickets.forEach((t) => {
                const contentIds = Array.from(
                    { length: t.quantity },
                    (_, i) => `${t.id}${t.groupPricingIndex ? `-${t.groupPricingIndex}` : ""}-${i + 1}`
                );

                ReactPixel.track("AddToCart", {
                    currency: artegrityEvent.currencyCode,
                    value: t.price * t.quantity,
                    content_ids: contentIds,
                    contentName: t.name,
                });
            });
        }

        return fetch(process.env.REACT_APP_PILOT_BE_URL + "/v0/payment/createOrder?" + param, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(b),
        })
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error(response.status + ": " + response.statusText);
                } else {
                    return response.json();
                }
            })
            .then((order) => {
                //if (
                //window.location.hostname === "localhost" &&
                //process.env.REACT_APP_MOCKING_ENABLED === "true"
                //) {
                //onApprove({ orderId: "9b31e519ed" }, {});
                //}
                return order.id;
            })
            .catch((error: Error) => {
                setLoading(false);
                setError(error.message);
            });
    }

    function onApprove(data: any, actions: any) {
        setLoading(true);
        privateLog("log", "To approve data: ", data);
        return fetch(
            process.env.REACT_APP_PILOT_BE_URL +
                `/v0/payment/capture?orderId=${data.orderID}&subscribeToBackstage=${signUpBackstageRef.current}&subscribeToPromotional=${signUpPromotionalRef.current}`,
            {
                method: "post",
            }
        )
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error(response.status + ": " + response.statusText);
                } else {
                    return response.json();
                }
            })
            .then((body) => {
                setSuccess("Order has successfully been made and will be delivered by mail");
                if (pixel) {
                    privateLog("info", "Pixel sending Purchase");
                    tickets.forEach((t) => {
                        const contentIds = Array.from(
                            { length: t.quantity },
                            (_, i) => `${t.id}${t.groupPricingIndex ? `-${t.groupPricingIndex}` : ""}-${i + 1}`
                        );

                        ReactPixel.track("Purchase", {
                            currency: artegrityEvent.currencyCode,
                            value: t.price * t.quantity,
                            content_ids: contentIds,
                            contentName: t.name,
                        });
                    });
                }
                let _body = body as OrderResponse;
                let orderInfo: OrderInfo = {
                    verifyLinks: _body.verifyLinks,
                    ticketNumbers: _body.ticketNumbers,
                    orderId: data.orderID,
                };
                setRes(orderInfo);
                setLoading(false);
            })
            .catch((error: Error) => {
                setLoading(false);
                setError(error.message);
            });
    }
    //TODO: put this back in
    //if (!process.env.REACT_APP_PAYPAL_CLIENT_ID) {
    //return <CircularProgress></CircularProgress>;
    //}
    if (res) {
        return <ArtegrityOrderSummary {...res}></ArtegrityOrderSummary>;
    }

    return (
        <Box
            sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "background.default",
                borderRadius: 5,
            }}
        >
            <TicketTable
                vat={artegrityEvent.taxInPercentage}
                tickets={tickets}
                setQuantity={setQuantity}
                currencyCode={artegrityEvent.currencyCode}
            ></TicketTable>
            <Box
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "flex-end",
                }}
            >
                {tickets.filter((t) => t.available !== undefined && t.available > 0).length > 0 && ticketShopState === EventState.ACTIVE ? (
                    <Box
                        sx={{
                            alignSelf: "center",
                            width: "100%",
                            maxWidth: 400,
                        }}
                    >
                        {process.env.REACT_APP_PAYPAL_CLIENT_ID ? (
                            <PayPalScriptProvider
                                options={{
                                    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                    currency: "EUR",
                                    intent: "capture",
                                    "disable-funding": "sofort,giropay,sepa,eps",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                    }}
                                >
                                    <FormControlLabel
                                        sx={{ color: "text.primary" }}
                                        labelPlacement="end"
                                        control={
                                            <Checkbox
                                                checked={signUpPromotional}
                                                onChange={() => {
                                                    setSignUpPromotional((value) => !value);
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    textAlign: "left", // Add this line
                                                }}
                                            >
                                                Sign Up for Promotional Emails from {artegrityEvent.organizer}
                                            </Typography>
                                        }
                                    ></FormControlLabel>
                                    <FormControlLabel
                                        sx={{ color: "text.primary" }}
                                        labelPlacement="end"
                                        control={
                                            <Checkbox
                                                checked={signUpBackstage}
                                                onChange={() => {
                                                    setSignUpBackstage((value) => !value);
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    textAlign: "left", // Add this line
                                                }}
                                            >
                                                Sign Up for Notifcations about new Content in the Digital Backstage for{" "}
                                                {artegrityEvent.title.length > 25
                                                    ? `${artegrityEvent.title.substring(0, 35)}....`
                                                    : artegrityEvent.title}
                                            </Typography>
                                        }
                                    ></FormControlLabel>
                                    <Divider sx={{ mt: 1, mb: 1 }}></Divider>
                                    <FormControlLabel
                                        sx={{ color: "text.primary" }}
                                        labelPlacement="end"
                                        control={
                                            <Checkbox
                                                checked={usePaypalEmail}
                                                onChange={() => {
                                                    useEmailRef.current = !usePaypalEmail;
                                                    setUsePaypalEmail(!usePaypalEmail);
                                                }}
                                            />
                                        }
                                        label={"Use Email Address from PayPal"}
                                    ></FormControlLabel>
                                    {artegrityEvent.isPersonalized &&
                                        tickets.map((ticket) => (
                                            <Box key={ticket.id} sx={{ width: "100%" }}>
                                                {[...Array(ticket.quantity)].map((_, index) => (
                                                    <TextField
                                                        sx={{ mt: 1 }}
                                                        fullWidth={true}
                                                        key={index}
                                                        label={`Name for ticket ${index + 1}`}
                                                        value={ticketNames[ticket.id]?.[index] || ""}
                                                        onChange={(e) => handleNameChange("" + ticket.id, index, e.target.value)}
                                                        required={true}
                                                    />
                                                ))}
                                            </Box>
                                        ))}
                                    {!usePaypalEmail ? (
                                        <TextField
                                            id="outlined-basic"
                                            label="Email"
                                            variant="outlined"
                                            fullWidth={true}
                                            value={email}
                                            onChange={(event) => {
                                                emailRef.current = event.target.value;
                                                setEmail(event.target.value);
                                            }}
                                            disabled={usePaypalEmail}
                                        />
                                    ) : null}
                                    {error ? (
                                        <Typography color="red" mb={1}>
                                            {error}
                                        </Typography>
                                    ) : null}
                                    {!success ? (
                                        <Typography color="red" mb={1}>
                                            {success}
                                        </Typography>
                                    ) : null}
                                    {loading ? <CircularProgress></CircularProgress> : null}
                                </Box>
                                <Box
                                    sx={{
                                        width: "100%",
                                        ml: -5,
                                        mt: 1,
                                    }}
                                >
                                    <PayPalButtons {...paypalbuttonTransactionProps} />
                                </Box>
                            </PayPalScriptProvider>
                        ) : null}
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            borderRadius: 5,
                            padding: 1,
                            justifyContent: "center",
                            mb: 2,
                        }}
                    >
                        <Typography alignSelf={"center"} variant="body1" color={"red"}>
                            {ticketShopState === EventState.PAST && "The Ticket Shop for this event is closed"}
                            {ticketShopState === EventState.DRAFT && "Tickets for this Event will soon be available"}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
