import { Cancel } from "@mui/icons-material";
import { Box, ButtonBase, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import { mediaInteraction } from "../../../../analytics/Posthog";
import { EventContext } from "../../../../contexts/EventContextProvider";
import { getLanguageText } from "../../../../languagesupport";
import { ContentElementProps, TextElement } from "../../../../types/bll";
import InteractionBar from "../components/InteractionBar";
import TextExpandedComponent from "./TextExpandedComponent";

import { isMobile } from "react-device-detect";

const TextComponent = (props: ContentElementProps) => {
    const { title, imageUrl, shareUrl, isEarlyAccess, description } = props.contentElement.data as TextElement;
    const { brightTheme } = React.useContext(EventContext);
    const [showFullText, setShowFullText] = React.useState<boolean>(props.isOpen);

    return (
        <ThemeProvider theme={brightTheme}>
            <Box
                sx={{
                    borderRadius: 5,
                    p: 2,
                    backgroundColor: "background.default",
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                }}
            >
                {showFullText ? (
                    <ButtonBase onClick={() => setShowFullText(false)} sx={{ mb: 1, alignSelf: "flex-end" }}>
                        <Cancel color="primary" sx={{ width: 35, height: 35 }}></Cancel>
                    </ButtonBase>
                ) : null}
                <ButtonBase
                    onClick={() => {
                        if (!showFullText) {
                            mediaInteraction(props.contentElement.docId);
                            setShowFullText(true);
                        }
                    }}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {imageUrl && (
                        <Box
                            component={"img"}
                            src={imageUrl}
                            alt="textImage"
                            sx={{
                                borderRadius: 2,
                                width: "100%",
                                height: "100%",
                                maxHeight: 600,
                                objectFit: "contain",
                            }}
                        ></Box>
                    )}
                    <Typography mt={imageUrl ? 1 : 0} variant="h2">
                        {getLanguageText(title)}
                    </Typography>
                    {description ? (
                        <Typography mt={imageUrl ? 1 : 2} variant="body1" textAlign={"justify"} sx={{ whiteSpace: "pre-wrap", hyphens: "manual" }}>
                            {!isMobile && getLanguageText(description).replace(/\\n/g, "\n").replace(/&shy;/g, "\u00AD")}
                            {isMobile &&
                                (getLanguageText(description).length > 150
                                    ? getLanguageText(description).replace(/\\n/g, "\n").replace(/&shy;/g, "\u00AD").slice(0, 150) + "..."
                                    : getLanguageText(description).replace(/\\n/g, "\n").replace(/&shy;/g, "\u00AD"))}
                        </Typography>
                    ) : null}
                </ButtonBase>
                {showFullText ? (
                    <TextExpandedComponent
                        {...(props.contentElement.data as TextElement)}
                        onClose={() => setShowFullText(false)}
                    ></TextExpandedComponent>
                ) : null}
                <InteractionBar
                    uploadedBy={props.contentElement.userId}
                    likedBy={props.contentElement.likedBy}
                    docRef={props.contentElement.docRef}
                    isEarlyAccess={isEarlyAccess}
                    shareUrl={shareUrl}
                ></InteractionBar>
            </Box>
        </ThemeProvider>
    );
};

export default TextComponent;
