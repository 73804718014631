/* eslint-disable no-unused-vars */
//TICKETSHOP

import { DocumentReference } from "firebase/firestore";
import { LanguageText, NFTType } from "./bll";

export interface TicketProvider {
    name: TicketProviderName;
    data: any;
    id: string;
    shopUrl: string;
}

// Type for the ticketsSoldPerDay data
export type TicketsSoldPerDay = {
    [date: string]: number; // Maps a date string to a number of tickets sold
};

// Type for the ticketsSoldByPrice data
export type TicketsSoldByPrice = {
    [price: string]: number; // Maps a price string to a number of tickets sold
};

export type OrderSummaryResponse = {
    total: number;
    totalAfterFees: number;
    ticketsSoldPerDay: TicketsSoldPerDay;
    ticketsSoldByPrice: TicketsSoldByPrice;
};

export enum TicketProviderName {
    TIXLY = "tixly",
    ARTEGRITY = "artegrity",
    LIPPU = "lippu",
    PUBLIC = "public",
}

export enum EventFrameType {
    WEBSHOP = "webshop",
}

export interface Story {
    docRef: DocumentReference;
    uploadedBy: string;
    id?: string; // The document ID from Firestore (optional here since it's not a part of the Firestore document itself but derived from the query)
    filename: string; // The unique ID used as filename in Firebase Storage
    url: string; // The download URL for the file
    uploadedAt: Date; // The timestamp indicating when the file was uploaded
    description: string;
    visibility: string;
    likedBy?: string[];
    // You can extend this with other attributes as necessary
}

export interface EventFrameData {
    url: string;
    offset: number;
}

export interface InfoElement {
    title: string;
    description: string;
}

export interface Font {
    name: string;
    path: string;
    fontSize?: number;
    fontWeight?: number;
    letterSpacing?: string;
}

export interface ColorPalette {
    backgroundColor: string;
    textColor: string;
}

export interface EventTheme {
    dark: ColorPalette;
    bright: ColorPalette;
    borderRadius: 0;
    headerFont: Font;
    bodyFont: Font;
    primaryColor: string;
    subHeaderFont?: Font;
}

export type TicketInfo = {
    name: string;
    nameShort: string;
    location: string;
    date: string;
    time: string;
    description: string;
};

export type FreeOrderResponse = {
    verifyLinks: string[];
    cvtIds: number[];
    orderId: string;
};

export type TicketCountResponse = {
    eventState: EventState;
    hasTicketsAvailable: boolean;
};

export type OrderInfo = {
    quantity: number;
    price: number;
    ticketType: string;
    orderId: string;
    currency: Currency;
    verifyLinks: string[];
    cvtIds: number[];
};

export type OrderResponse = {
    verifyLinks: string[];
    cvtIds: number[];
};

//END

export type InstagramResult = {
    status: string;
    instagramUsername: string;
};

export type OrderResult = {
    txHash: string;
    clientId: string;
    signature: string;
};

export enum Currency {
    EUR = "EUR",
    CHF = "CHF",
    USD = "USD",
}

//NFT Types
export interface MintCvt extends Cvt {
    secret: string;
}

export type NFT = {
    chain: string | null;
    smartContractAddress: string;
    cvtId: number;
    clientId: string;
    smartContractVersion: string;
};
export type Cvt = {
    chain: string | null;
    smartContractAddress: string;
    cvtId: number;
    clientId: string;
    signature: string;
    smartContractVersion: string;
};

export type Voucher = {
    cvtId: number;
    signature: string;
    recipient: string;
};

export type EventPreview = {
    name: string;
    date: string;
    state: EventState;
    id: string;
};

export enum LanguageCode {
    SV = "sv",
    FI = "fi",
    EN = "en",
    DE = "de",
}

export interface EventDataInterface {
    //TODO: make enum
    onlyEventRoom?: boolean;
    websiteUrl?: string;
    id: string;
    title: LanguageText;
    subtitle: LanguageText;
    description: LanguageText;
    ticketProvider?: TicketProvider;
    imagePath: string;
    frame?: EventFrame;
    company: Company;
    members: DocumentReference[];
    ticketCode?: string;
    theme: EventTheme;
    infoList?: InfoElement[];
    supportedLanguages: LanguageCode[];
    credits?: string;
    sponsorImages?: string[];
    analyticsVersion?: number;
    ga4MeasurementId?: string;
    multiEvent?: boolean;
    pixel?: string;
}

export interface SvenskaTeaternFrameData extends EventFrameData {
    footer: string;
    footer_helper: string;
    primary: string;
    secondary: string;
}

export interface EventFrame {
    type: EventFrameType;
    data: EventFrameData;
}

export interface Company {
    favicon: string;
    icon: string;
    name: string;
    socials?: Social[];
}

interface GroupTicketPricing {
    name: string;
    groupPricingIndex: number;
    description: string;
    priceReduction: number;
    minQuantity: number;
}

interface PricingTable {
    pricingIndex: number;
    name: string;
    description: string | null;
    price: number;
    currIndex: number;
    maxIndex: number;
    groupPricing?: GroupTicketPricing[];
}

export interface ArtegrityEventData {
    isPersonalized?: boolean;
    title: string;
    currencyCode: Currency;
    pricingTable: PricingTable[];
    infos: string[];
    organizer: string;
    socials: Social[];
    designer: Designer;
    ticketType: string;
    eventState: string;
    taxInPercentage: number;
}

export type Designer = {
    name: string;
    link: string;
};

export type Social = {
    name: string;
    link: string;
    icon?: string;
};

export const getColorFromEventState = (state: EventState) => {
    return state === EventState.ACTIVE ? "#008450" : state === EventState.DRAFT ? "#EFB700" : state === EventState.PAST ? "#DADADA" : "default";
};

export interface MHEvent {
    id: number;
    eventDetails: EventDetailsEmbedabble;
    ticketInfo: TicketInfoEmbedabble;
    ticketDesign: TicketDesignEmbedabble;
    smartContractAddress: string;
    eventState: EventState;
    currIndex: number;
    maxIndex?: number;
}

export interface EventDetailsEmbedabble {
    title: string;
    description: string;
    date?: string;
    time?: string;
    venueName?: string;
    venueAddress?: string;
}

export interface TicketInfoEmbedabble {
    productDescription?: string;
    pricingType: PricingType;
    pricingTable: PricingElement[];
    taxInPercentage?: number;
}

export interface TicketDesignEmbedabble {
    designerName?: string;
    designerSocials?: SocialEmbeddable[];
    ticketType?: TicketType;
}

export interface PricingElement {
    name: string;
    price: number;
    amount: number;
}

export enum PricingType {
    TIERED,
    SINGLE,
}

export enum EventState {
    DRAFT = "DRAFT",
    ACTIVE = "ACTIVE",
    PAST = "PAST",
    NONE = "NONE",
}

export enum TicketType {
    UNIQUE,
    SINGLE,
}

export interface SocialEmbeddable {
    name: string;
    link: string;
}
